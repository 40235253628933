import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteBtnComponent } from './elements/buttons/white-btn/white-btn.component';
import { MatRippleModule } from '@angular/material/core';
import { BlueBtnComponent } from './elements/buttons/blue-btn/blue-btn.component';
import { SideBarComponent } from './elements/side-bar/side-bar.component';
import { SecondaryBtnComponent } from './elements/buttons/secondary-btn/secondary-btn.component';
import { MatIconModule } from '@angular/material/icon';
import { RedBtnComponent } from './elements/buttons/red-btn/red-btn.component';
import { RouterLink, RouterModule } from '@angular/router';
import { SearchBarComponent } from './elements/search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastComponent } from './elements/toast/toast.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BreadcrumbComponent } from './elements/breadcrumb/breadcrumb.component';
import { SelectionComponent } from './elements/selection/selection.component';
import { DeleteComponent } from './elements/modals/delete/delete.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SpinnerComponent } from './elements/spinner/spinner.component';
import { AddKeywordsToListComponent } from './elements/modals/add-keywords-to-list/add-keywords-to-list.component';
import { AddKeywordListComponent } from './elements/modals/add-keyword-list/add-keyword-list.component';
import { DeleteListComponent } from './elements/modals/delete-list/delete-list.component';
import { ScanningLoaderComponent } from './elements/scanning/scanning-loader.component';
import { DoughnutChartComponent } from './elements/charts/doughnut/doughnut-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { ScanErrorComponent } from './elements/modals/scan-error/scan-error.component';
import { LargeSpinnerComponent } from './elements/large-spinner/large-spinner.component';
import { SubscribeModalComponent } from './elements/modals/subscribe/subscribe-modal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BackHomepageHeaderComponent } from './elements/back-homepage-header/back-homepage-header.component';
import { FilterPipe } from './pipes/filter-by-name.pipe';
import { ErrorComponent } from './elements/toast/error/error.component';
import { EditDeleteReportComponent } from './elements/edit-delete-report/edit-delete-report.component';
import { EditNameComponent } from './elements/modals/edit-name/edit-name.component';
import { EllipsisSpinnerComponent } from './elements/ellipsis-spinner/ellipsis-spinner.component';
import { SlideToggleBtnComponent } from './elements/slide-toggle-btn/slide-toggle-btn.component';
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TooltipComponent } from './elements/tooltip/tooltip.component';
import {CdkScrollable} from "@angular/cdk/overlay";
import {MatTooltipModule} from "@angular/material/tooltip";
import { RedirectModalComponent } from './elements/modals/redirect-modal/redirect-modal.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { ErrorComponent as ModalErrorComponent } from './elements/modals/error/error.component';
import {ConfirmComponent} from "./elements/modals/confirm/confirm.component";
import {IconBtnComponent} from "./elements/buttons/icon-btn/icon-btn.component";
import {ButtonComponent} from "./elements/buttons/v2/btn/button.component";
import { TermsAndConditionsModalComponent } from './elements/modals/terms-and-conditions/terms-and-conditions-modal.component';
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { GenericOrganizationComponent } from './elements/modals/generic-organization/generic-organization.component';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
  declarations: [
    WhiteBtnComponent,
    BlueBtnComponent,
    SideBarComponent,
    SecondaryBtnComponent,
    RedBtnComponent,
    SearchBarComponent,
    ToastComponent,
    BreadcrumbComponent,
    SelectionComponent,
    DeleteComponent,
    SpinnerComponent,
    AddKeywordsToListComponent,
    AddKeywordListComponent,
    GenericOrganizationComponent,
    DeleteListComponent,
    ScanningLoaderComponent,
    DoughnutChartComponent,
    ScanErrorComponent,
    LargeSpinnerComponent,
    SubscribeModalComponent,
    BackHomepageHeaderComponent,
    FilterPipe,
    CapitalizePipe,
    ErrorComponent,
    EditDeleteReportComponent,
    EditNameComponent,
    EllipsisSpinnerComponent,
    SlideToggleBtnComponent,
    TooltipComponent,
    RedirectModalComponent,
    ModalErrorComponent,
    ConfirmComponent,
    IconBtnComponent,
    ButtonComponent,
    TermsAndConditionsModalComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    RouterLink,
    MatInputModule,
    FormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatIconModule,
    MatDialogModule,
    RouterModule,
    ReactiveFormsModule,
    NgChartsModule,
    MatTabsModule,
    MatSlideToggleModule,
    CdkScrollable,
    MatTooltipModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [
    WhiteBtnComponent,
    BlueBtnComponent,
    SideBarComponent,
    SecondaryBtnComponent,
    RedBtnComponent,
    SearchBarComponent,
    MatSnackBarModule,
    BreadcrumbComponent,
    SelectionComponent,
    DeleteComponent,
    SpinnerComponent,
    ScanningLoaderComponent,
    DoughnutChartComponent,
    LargeSpinnerComponent,
    BackHomepageHeaderComponent,
    FilterPipe,
    CapitalizePipe,
    EditDeleteReportComponent,
    EllipsisSpinnerComponent,
    SlideToggleBtnComponent,
    TooltipComponent,
    IconBtnComponent,
    ButtonComponent,
  ],
})
export class SharedModule {}
