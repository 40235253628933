import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: false
})
export class SearchBarComponent {
  @Output() action = new EventEmitter();
  @Input() searchBarLabel: string = '';
  searchParams: string = '';

  sendInputValue(): any {
    this.action.emit(this.searchParams);
  }
}
