/* eslint-disable */
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-redirect-modal',
    templateUrl: './redirect-modal.component.html',
    styleUrls: ['./redirect-modal.component.scss'],
    standalone: false
})
export class RedirectModalComponent {
  constructor(
    public dialogRef: MatDialogRef<RedirectModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: string;
    }
  ) {}

}
