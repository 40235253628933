<h1 mat-dialog-title>{{ data.title }} <mat-icon mat-dialog-close>close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>{{ data.primaryMessage }}</p>
  <br />
  <p>{{ data.secondaryMessage }}</p>
</div>

<div class="new-keyword-list">
  <mat-form-field  hideRequiredMarker="true">
    <mat-label>{{ data.labelName ? data.labelName : "New Name" }}</mat-label>
    <input [formControl]="inputName" matInput />
    <mat-hint *ngIf="inputName.touched && inputName.invalid">
      <span>!</span>
      {{ data.hintText ? data.hintText : "Required field" }}
    </mat-hint>
  </mat-form-field>
</div>

<div class="actions" mat-dialog-actions>
  <kwfSecondaryButton [text]="'cancel'" [showArrow]="false" mat-dialog-close></kwfSecondaryButton>
  <kwfBlueButton
    [text]="data.action"
    (action)="close()"
    [disabled]="inputName.touched && inputName.invalid"
    [ngStyle]="{ opacity: inputName.touched && inputName.invalid ? 0.4 : 1 }"
  >
  </kwfBlueButton>
</div>
