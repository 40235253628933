import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-back-homepage-header',
    templateUrl: './back-homepage-header.component.html',
    styleUrls: ['./back-homepage-header.component.scss'],
    standalone: false
})
export class BackHomepageHeaderComponent {
  constructor(readonly router: Router) {}
}
