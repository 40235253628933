import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationsComponent } from '../../../../organizations/organizations.component';
import { PaymentMethod } from '../../../interfaces/user/user.interface';

export enum DialogType {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  RESTORE = 'restore'
}

@Component({
    selector: 'app-generic-organization',
    templateUrl: './generic-organization.component.html',
    styleUrls: ['./generic-organization.component.scss'],
    standalone: false
})
export class GenericOrganizationComponent {
  dialogType: DialogType = DialogType.CREATE;
  form: FormGroup;
  paymentMethodList = [
    { value: PaymentMethod.STRIPE, label: 'Stripe' },
    { value: PaymentMethod.INVOICE, label: 'Invoice' },
  ];
  constructor(
    public dialogRef: MatDialogRef<OrganizationsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: DialogType;
      organization?: {
        name: string;
        size: number;
        ownerEmail: string;
        ownerId?: string;
        billingMethod: PaymentMethod;
      };
    }
  ) {
    this.dialogType = this.data.action;

    this.form = new FormGroup({

      name: new FormControl<string | null | undefined>(!this.isCreate() ? this.data.organization?.name : null,
        Validators.required
      ),
      size: new FormControl<number | undefined>(!this.isCreate() ? this.data.organization?.size : 1,
        [Validators.required, Validators.min(1)]
      ),
      ownerEmail: new FormControl<string | null>(null,
        !this.isCreate() ? [] : [Validators.required, Validators.email]
      ),
      billingMethod: new FormControl<string | null | undefined>(!this.isCreate() ? this.data.organization?.billingMethod : null,
        Validators.required
      ),
    });
  }

  close(): void {
    this.dialogRef.close(this.form.valid ? this.form.value : null);
  }

  isCreate(): boolean {
    return this.dialogType === DialogType.CREATE;
  }

  protected readonly DialogType = DialogType;
}
