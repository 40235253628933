/* eslint-disable */
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-name',
    templateUrl: './edit-name.component.html',
    styleUrls: ['./edit-name.component.scss'],
    standalone: false
})
export class EditNameComponent {
  inputName: FormControl<string | null> = new FormControl(null, Validators.required);

  constructor(
    public dialogRef: MatDialogRef<EditNameComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: string;
      currentName: string;
      labelName: string;
      hintText: string;
    }
  ) {
    if (this.data.currentName && this.data.currentName.trim() !== '')
      this.inputName.setValue(this.data.currentName);
  }

  close(): void {
    if (this.validForm()) {
      return this.dialogRef.close(this.inputName.value);
    }
    return this.dialogRef.close();
  }

  validForm(): boolean {
    return !!this.inputName.value && this.inputName.value?.trim() !== '';
  }
}
