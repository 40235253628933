import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'kwfWhiteButton',
    templateUrl: './white-btn.component.html',
    styleUrls: ['./white-btn.component.scss', '../btn.component.scss'],
    standalone: false
})
export class WhiteBtnComponent implements OnInit {
  @Input() text!: string;
  @Input() disabled = false;
  @Input() color: 'blue' | 'red' = 'blue';
  @Input() icon!: string;
  @Input() goToIcon: boolean = false;
  @Input() small = false;
  @Output() action = new EventEmitter();

  ngOnInit(): void {}
}
