import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'selection',
    templateUrl: './selection.component.html',
    styleUrls: ['./selection.component.scss'],
    standalone: false
})
export class SelectionComponent {
  @Input() primaryButton!: string;
  @Input() secondaryButton!: string;

  @Output() primaryAction = new EventEmitter();
  @Output() secondaryAction = new EventEmitter();

  @Input() item!: string;
  @Input() length!: number;
}
