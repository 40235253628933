import { Component, Input } from '@angular/core';
import { DeleteComponent } from '../modals/delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { EditNameComponent } from '../modals/edit-name/edit-name.component';
import { ScanProjectService } from '../../../services/scan-project.service';
import { Router } from '@angular/router';
import { ToastService } from '../../../services/toast.service';

@Component({
    selector: 'app-edit-delete-report',
    templateUrl: './edit-delete-report.component.html',
    styleUrls: ['./edit-delete-report.component.scss'],
    standalone: false
})
export class EditDeleteReportComponent {
  @Input() projectId: string = '';
  @Input() currentProjectName: string = '';

  constructor(
    public dialog: MatDialog,
    readonly scanService: ScanProjectService,
    readonly route: Router,
    readonly toastService: ToastService
  ) {}

  editReport(): void {
    const dialogRef = this.dialog.open(EditNameComponent, {
      height: '300px',
      width: '500px',
      data: {
        title: `Rename report`,
        primaryMessage: 'Enter the new project name that you wish to be saved',
        action: 'Save changes',
        currentName: this.currentProjectName ? this.currentProjectName : '',
        labelName: 'New Project Name',
        hintText: 'Project Name is required',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) return;
      const newProjectName: string = dialogRef.componentInstance.inputName.value
        ? dialogRef.componentInstance.inputName.value
        : '';
      void this.scanService.editProjectName(this.projectId, newProjectName).then(() => {
        void this.route.navigate(['/dashboard']).then(() => {
          this.toastService.callToast(
            'Saved changes to the report!',
            'The report has been successfully renamed'
          );
        });
      });
    });
  }

  deleteReport(): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      height: '300px',
      width: '500px',
      data: {
        title: `Delete report`,
        primaryMessage:
          'By deleting this report, you will permanently remove this report and its information from the system.',
        secondaryMessage: 'This is an irreversible action.',
        action: 'Delete report',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) return;
      void this.scanService.deleteProject(this.projectId).then(() => {});
    });
  }
}
