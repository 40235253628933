import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ellipsis-spinner',
    templateUrl: './ellipsis-spinner.component.html',
    styleUrls: ['./ellipsis-spinner.component.scss'],
    standalone: false
})
export class EllipsisSpinnerComponent {
  @Input() numberOfDots: number = 3;
  @Input() loadingMessage: string = 'Loading';
  divDots: any[] = [];

  ngOnInit(): void {
    this.setDots();
  }

  setDots(): void {
    for (let i = 1; i <= this.numberOfDots; i++) this.divDots.push('dot');
  }
}
