import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    standalone: false
})
export class TooltipComponent {
  @Input() tooltipText: string = 'These are possible lower-risk alternatives. Ensure statements you are making are truthful, not misleading, and that you have substantiation';
}
