<h1 mat-dialog-title>{{ data.title }} <mat-icon mat-dialog-close>close</mat-icon></h1>
<div class="content" mat-dialog-content>
  <p>{{ data.primaryMessage }}</p> <p>{{ data.secondaryMessage }}</p>
</div>

<div class="modal">
  <form [formGroup]="form">
    <mat-form-field *ngIf="isCreate()"  hideRequiredMarker>
      <mat-label>Owner E-mail</mat-label>
      <input formControlName="ownerEmail" matInput />
      <mat-hint *ngIf="form.get('ownerEmail')?.touched && form.get('ownerEmail')?.invalid">
        <span>!</span> E-mail is required
      </mat-hint>
    </mat-form-field>

    <mat-form-field  hideRequiredMarker>
      <mat-label>Organization Name</mat-label>
      <input formControlName="name" matInput />
      <mat-hint *ngIf="form.get('name')?.touched && form.get('name')?.invalid">
        <span>!</span> Organization name is required
      </mat-hint>
    </mat-form-field>

    <div class="split-row">
      <mat-form-field  hideRequiredMarker>
        <mat-label>Payment Method</mat-label>
        <mat-select formControlName="billingMethod" disableOptionCentering panelClass="dropdown">
          <mat-option *ngFor="let method of paymentMethodList" [value]="method.value">
            {{ method.label }}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="form.get('billingMethod')?.touched && form.get('billingMethod')?.invalid">
          <span>!</span> Payment Method is required
        </mat-hint>
      </mat-form-field>

      <mat-form-field  class="compact" hideRequiredMarker>
        <mat-label>Size</mat-label>
        <input formControlName="size" matInput type="number" value="1" min="1" class="seats" />
        <mat-hint *ngIf="form.get('size')?.touched && form.get('size')?.invalid">
          <span>!</span> Default size is 1 seat
        </mat-hint>
      </mat-form-field>
    </div>
  </form>
</div>

<div class="actions" mat-dialog-actions>
  <kwfSecondaryButton [text]="'cancel'" [showArrow]="false" mat-dialog-close></kwfSecondaryButton>
  <kwfBlueButton [text]="data.action" (action)="close()"></kwfBlueButton>
</div>
