import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'FilterByName',
    standalone: false
})
export class FilterPipe implements PipeTransform {
  transform(value: any, args?: string): any {
    if (!value) return null;
    if (!args) return value;

    args = args.toLowerCase();

    return value.filter(function (data: { name: string }) {
      return JSON.stringify(data.name)
        .toLowerCase()
        .includes(args ?? '');
    });
  }
}
