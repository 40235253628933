/* eslint-disable */
import {environment} from '../environments/environment';
import { IShareType } from "./services/organization.service";

export class Endpoints {
  static getUserID(): string {
    const user = JSON.parse(<string>localStorage.getItem('user'));
    return user && user.uid;
  }

  static getOrganizationId(): string {
    return <string>localStorage.getItem('currentOrgId');
  }

  /*AUTH ENDPOINTS*/
  static forgotPassword: string = `${environment.baseUrl}/auth/forgot-password`;
  static changePassword: string = `${environment.baseUrl}/auth/change-password`;
  static createUser: string = `${environment.baseUrl}/users`;

  /* USERS ENDPOINTS */
  static getUser(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}`;
  }

  static getAllUsers(): string {
    return `${environment.baseUrl}/users`;
  }

  /* ORGANIZATIONS ENDPOINTS */
  static createOrganization(): string {
    return `${environment.baseOrganizationsUrl}/organizations`;
  }

  static getOrganization(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}`;
  }

  static updateOrganization(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}`;
  }

  static deleteOrganization(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}`;
  }

  static restoreOrganization(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}`;
  }

  static getUserOrganizations(): string {
    return `${environment.baseOrganizationsUrl}/users/${this.getUserID()}/organizations`;
  }

  static getAllOrganizations(): string {
    return `${environment.baseOrganizationsUrl}/organizations`;
  }

  static getAllUsersFromOrganization(organizationId: string, showRemoved = false): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}/users?showRemoved=${showRemoved}`;
  }

  static invitationsFromOrganization(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}/invitations`;
  }

  static userFromOrganization(organizationId: string, userId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}/users/${userId}`;
  }

  static generateOrganzationInvite(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/invitations/organizations/${organizationId}`;
  }

  static resendOrganizationInvite(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/invitations/organizations/resend/${organizationId}`;
  }

  static acceptInvitation(token: string): string {
    return `${environment.baseOrganizationsUrl}/invitations/${token}`;
  }

  static deleteUserInvitationsFromOrganization(organizationId: string, userId: string): string {
    return `${environment.baseOrganizationsUrl}/invitations/organizations/${organizationId}/users/${userId}`;
  }

  static getOrganizationByToken(token: string): string {
    return `${environment.baseOrganizationsUrl}/invitations/${token}/organizations`;
  }

  static transferOwnership(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}/transfer-ownership`;
  }

  static getUserRole(organizationId: string, userId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}/users/${userId}/role`;
  }

  static deleteEmptyOrganization(organizationId: string): string {
    return `${environment.baseOrganizationsUrl}/organizations/${organizationId}/delete-empty`;
  }

  /*KEYWORD ENDPOINTS*/
  static allKeywords(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords`;
  }

  static allOrganizationKeywords(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/organization/${this.getOrganizationId()}/keywords`;
  }

  /*KEYWORD ENDPOINTS*/
  static keywordsSuggestByAI(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords/suggest/byAI`;
  }

  /*KEYWORD LIST ENDPOINTS*/
  static keywordList(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keyword-lists`;
  }

  static organizationKeywordList(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/organizationId/${this.getOrganizationId()}/keyword-lists`;
  }

  static keywordListToggleShare(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/organization/${this.getOrganizationId()}/keyword-lists/toggle-share`
  }

  static keywordListById(id: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keyword-lists/${id}`;
  }

  static smartSuggestion(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords/suggest/byAI`;
  }

  static importKeywordList(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/keywords-lists/import`;
  }

  /* SCAN ENDPOINTS */
  static scanProjects(searchName?: string, page = 0): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects?page=${page}${searchName != null ? '&search=' + searchName : ''}`;
  }

  static getScanProjectById(projectId: string) {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}`;
  }

  static getScanProjectsByOrg(searchName?: string, page = 0, filterType: IShareType = 'ALL'): string {
    let ret = `${environment.baseUrl}/users/${this.getUserID()}/organization/${this.getOrganizationId()}/scan-projects?page=${page}${searchName != null ? '&search=' + searchName : ''}`;
    ret += `&filterType=${filterType}`;
    return ret;
  }

  static getScanProjectsWebsiteByOrg2(scanProjectId: string, searchName?: string, page = 0, filterType: IShareType = 'ALL'): string {
    let ret = `${environment.baseUrl}/users/${this.getUserID()}/organization/${this.getOrganizationId()}/scan-projects2/${scanProjectId}?page=${page}${searchName != null ? '&search=' + searchName : ''}`;
    ret += `&filterType=${filterType}`;
    return ret;
  }

  static getScanProjectsPDFByOrg2(scanProjectId: string): string {
    let ret = `${environment.baseUrl}/users/${this.getUserID()}/organization/${this.getOrganizationId()}/scan-projects2/pdf/${scanProjectId}`;
    return ret;
  }

  static projectToggleShare(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/organization/${this.getOrganizationId()}/scan-projects/toggle-share`
  }

  static scanOCRProject(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/ocr`
  }

  static scanMultipleDocs(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/files`
  }

  static scanText(): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-texts`
  }

  static scanMedia(projectId?: string): string {
    return `${environment.baseUrl}/media/scan-media${projectId != null ? '/' + projectId : ''}`;
  }

  static validateExceptions() {
    return `${environment.baseUrl}/exceptions/validate`;
  }

  static replace(type: 'docx' | 'xlsx'): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/replace/${type}`;
  }

  static scanReportsProjects(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/reports`;
  }

  static videoReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/video/reports`;
  }

  static audioReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/audio/reports`;
  }

  static websiteReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/website/reports`;
  }

  static websiteKeywordDetail(scanProjectId: string, keywordId: string, query: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${scanProjectId}/website/reports/keyword/${keywordId}?query=${query}`;
  }

  static generateFileReport(projectId: string, ownerId: string): string {
    return `${environment.baseUrl}/users/${ownerId}/scan-projects/${projectId}/website/generateFileReport`;
  }

  static generateOCRFileReport(projectId: string, ownerId: string): string {
    return `${environment.baseUrl}/users/${ownerId}/scan-projects/${projectId}/ocr/generateFileReport`;
  }

  static generateDocBatchFileReport(projectId: string, ownerId: string): string {
    return `${environment.baseUrl}/users/${ownerId}/scan-projects/${projectId}/files/generateFileReport`;
  }

  static generateDocBatchFileSingleReport(projectId: string, path: string, ownerId: string): string {
    return `${environment.baseUrl}/users/${ownerId}/scan-projects/${projectId}/files/generateFileReport?file=${path}`;
  }

  static scanOCRTranscript(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/ocr/transcripts`;
  }

  static ocrReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/ocr/reports`;
  }

  static multipleDocReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/files/reports`;
  }

  static multipleDocTranscript(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/files/transcripts`;
  }

  static scanTranscript(projectId: string): string {
    return `${
      environment.baseUrl
    }/users/${this.getUserID()}/scan-projects/${projectId}/reports/transcripts`;
  }

  static scanWebsiteReportTranscript(projectId: string): string {
    return `${
      environment.baseUrl
    }/users/${this.getUserID()}/scan-projects/${projectId}/website/transcripts`;
  }

  static editOrDeleteReport(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}`;
  }

  static cancelScan(projectId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/scan-projects/${projectId}/cancel`;
  }

  /* SUBSCRIPTION ENDPOINTS */
  static getPrices(): string {
    return `${environment.baseUrl}/subscription/prices`;
  }

  static getUserSubscription(email: string): string {
    return `${environment.baseUrl}/subscription/${email}`;
  }

  static validateCheckout(checkoutId: string): string {
    return `${environment.baseUrl}/subscription/checkout/${checkoutId}`;
  }

  static snapshotKeywords(snapshotKeywordId: string): string {
    return `${environment.baseUrl}/users/${this.getUserID()}/snapshot-keywords/${snapshotKeywordId}/suggestions`;
  }

  static deleteUser(id: string): string {
    return `${environment.baseUrl}/users/usersByAdmin/${id}`;
  }

  static editUser(id: string): string {
    return `${environment.baseUrl}/users/usersByAdmin/${id}`;
  }

  static changeUserPassword (id: string): string {
    return `${environment.baseUrl}/users/change-password/${id}`;
  }

  /* FLAGGED WEBSITES */
  static flaggedWebsites(): string {
    return `${environment.baseUrl}/website-flags`;
  }

  /* INVOICES ENDPOINTS*/
  static invoices(): string {
    return `${environment.baseUrl}/invoices`;
  }

  static getInvoice(userId: string): string {
    return `${environment.baseUrl}/invoices/${userId}`;
  }
}
