import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'kwfSecondaryButton',
    templateUrl: './secondary-btn.component.html',
    styleUrls: ['./secondary-btn.component.scss'],
    standalone: false
})
export class SecondaryBtnComponent implements OnInit {
  @Input() text!: string;
  @Input() disabled = false;
  @Input() showArrow = true;
  @Output() action = new EventEmitter();

  ngOnInit(): void {}
}
