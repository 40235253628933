/* eslint-disable */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetKeywordListAPIInterface } from '../../../interfaces/keyword-list/get-keyword-list-API.interface';
import { KeywordsListService } from '../../../../services/keywords-list.service';

@Component({
    selector: 'app-add-keywords-to-list',
    templateUrl: './add-keywords-to-list.component.html',
    styleUrls: ['./add-keywords-to-list.component.scss'],
    standalone: false
})
export class AddKeywordsToListComponent implements OnInit {
  allKeywordsList: any[] = [];
  filteredData: any[] = [];
  public selectedList: any[] = [];
  constructor(
    readonly keywordService: KeywordsListService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      primaryMessage: string;
      secondaryMessage: string;
      action: string;
    }
  ) {}

  ngOnInit(): void {
    this.getKeywordList();
  }

  getKeywordList(): void {
    this.keywordService.getKeywordList().subscribe({
      next: (response) => {
        this.allKeywordsList = response;
        this.filteredData = response;
      },
      error: (error): void => {
        return console.error(error);
      },
    });
  }

  getInputValue(inputValue: string): void {
    if (!this.allKeywordsList.length) return;
    this.filteredData = [];
    if (inputValue === '') {
      this.filteredData = this.allKeywordsList;
      return;
    }
    this.allKeywordsList.map((item: any): void => {
      // Object.keys(item).map((index: any): void => {
      if (item['name']) {
        const formattedValue: string = item['name'].toString().toLowerCase();
        if (formattedValue.includes(inputValue.toLowerCase()) && !this.filteredData.includes(item))
          this.filteredData.push(item);
      }
      // });
    });
  }

  getSelectedList(list: any): void {
    if (!this.selectedList.includes(list)) this.selectedList.push(list);
  }
}
